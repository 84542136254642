<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Add Room
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-snackbar v-model="snackbar" color="error" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-card-text> 
            <v-container>
                <form>
                    <v-select v-model="editedData.roomCategoryID"
                              :items="houseType"
                              @change="getRoom"
                              label="Select House"
                              item-value="id"
                              item-text="value"
                              @input="$v.editedData.roomCategoryID.$touch()"
                              @blur="$v.editedData.roomCategoryID.$touch()"></v-select>

                    <v-select v-model="editedData.roomTypeID"
                              :items="roomType"
                              label="Select Room Type"
                              :error-messages="roomTypeErrors"
                              required
                              item-value="id"
                              item-text="value"
                              @input="$v.editedData.roomTypeID.$touch()"
                              @blur="$v.editedData.roomTypeID.$touch()"></v-select>

                    <v-text-field v-model="editedData.roomName"
                                  label="Room Number"
                                  :error-messages="roomNameErrors"
                                  required
                                  @input="$v.editedData.roomName.$touch()"
                                  @blur="$v.editedData.roomName.$touch()"></v-text-field>
                    <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->

                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createFareForm",
        props: ["success"],
        //watch: {
        //    "editedData.roomcategoryid": {
        //        handler: async function (val, oldVal) {
        //            if (val != oldVal) {
        //                this.error = true;
        //                this.houseType = [];
        //                this.branch = [];
        //                const divisionBranch = await axios.get("Hotel/HouseList", {
        //                    params: { RoomCategoryId: val }
        //                });

        //                console.log('Divisionlist', divisionBranch);
        //                const { data } = await axios.get("Hotel/GetRoomListByHouse" + val);
        //                this.roomType = data

        //                this.houseType = divisionBranch.data;
        //                console.log('DivisionLIstt', this.division);
        //                this.editedData.roomcategoryid = val;
        //            }
        //        },
        //        deep: true
        //},
        computed: {
            houseTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.roomCategoryID.$dirty) return errors;
                !this.$v.editedData.roomCategoryID.required &&
                    errors.push("House is required.");
                return errors;
            },
            roomTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.roomTypeID.$dirty) return errors;
                !this.$v.editedData.roomTypeID.required &&
                    errors.push("Room is required.");
                return errors;
            },
            roomNameErrors() {
                const errors = [];
                if (!this.$v.editedData.roomName.$dirty) return errors;
                !this.$v.editedData.roomName.required &&
                    errors.push("Room Number is required.");
                return errors;
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                returnData: [],
                houseType: [],
                roomType: [],
                show: false,
                updated: false,
                snackbar: false,
                editedData: {
                    roomCategoryID: "",
                    roomTypeID: "",
                    roomName: "",

                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                roomCategoryID: { required },
                roomTypeID: { required },
                roomName: { required },
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                //axios.get('Hotel/RoomList').then((response) => {
                //    this.roomType = response.data;
                //})
                await axios.get('Hotel/HouseList', {}).then(response => {
                    this.houseType = response.data
                });
                this.updated = false;

            },
            async getRoom(val) {
                const roomList = await axios.get('Hotel/RoomList/' + val);
                this.roomType = roomList.data
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        RoomCategoryId: parseInt(this.editedData.roomCategoryID),
                        RoomTypeId: parseInt(this.editedData.roomTypeID),

                        Status: true,
                        RoomNumber: this.editedData.roomName,

                    };
                    axios.post("Hotel/InsertIntoHotelRoomAsync", param).then(response => {
                        if (response.data.success) {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Room Created Successfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        } else {
                            this.snackbar = true
                            this.callbackResponse.message = response.data.errors[0];
                        }

                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>