 <template>
    <v-card>
        <v-card-title>
            <span class="headline">Edit Room</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <form>
                    <v-select v-model="editedData.fareID"
                              :items="fareData"
                              label="Fare Type"
                              item-value="id"
                              item-text="value"></v-select>


                    <v-autocomplete v-model="editedData.roomCategoryID"
                                    :items="houseType"
                                    @change="getRoom"
                                    label="Select House"
                                    item-value="id"
                                    item-text="value"
                                    @input="$v.editedData.roomCategoryID.$touch()"
                                    @blur="$v.editedData.roomCategoryID.$touch()"
                                    ></v-autocomplete>


                    <v-autocomplete v-model="editedData.roomTypeID"
                                    :items="roomType"
                                    label="Select Room Type"
                                    item-value="id"
                                    item-text="value"
                                    @input="$v.editedData.roomTypeID.$touch()"
                                  @blur="$v.editedData.roomTypeID.$touch()"></v-autocomplete>


                    <v-text-field v-model="editedData.roomNumber"
                                  label="Room Name"
                                  :error-messages="roomNumberErrors"
                                  required
                                  @input="$v.editedData.roomNumber.$touch()"
                                  @blur="$v.editedData.roomNumber.$touch()"></v-text-field>
                    <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->


                    <v-btn class="mr-4" @click="submit">submit</v-btn>
                    <v-btn @click="clear">clear</v-btn>
                </form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createFareForm",
        props: ["success", "editData"],
        computed: {
            fareIDErrors() {
                const errors = [];
                if (!this.$v.editedData.fareID.$dirty) return errors;
                !this.$v.editedData.fareID.required &&
                errors.push("Room Type is required.");
                return errors;
            },
            roomNumberErrors() {
                const errors = [];
                if (!this.$v.editedData.roomNumber.$dirty) return errors;
                !this.$v.editedData.roomNumber.required &&
                errors.push("Room Category is required.");
                return errors;
            }
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (val) {
                        this.updated = false;
                        this.$emit("formResponseRoom", this.returnData);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                returnData: [],
                houseType: [],
                roomType: [],
                fareData: [],
                show: false,
                updated: false,
                editedData: {
                    fareID: "",
                    roomNumber: "",
                    roomCategoryID: "",
                    roomTypeID:"",
                }
            };
        },
        validations: {
            editedData: {
                fareID: {required},
                roomNumber: { required },
                roomCategoryID: { required },
                roomTypeID: { required },


            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            async formData() {
                axios.get('Hotel/DDLRoomFareListAsync', {}).then(response => {
                    this.fareData = response.data
                });
                await axios.get("Hotel/HouseList", {}).then(response => {
                    this.houseType = response.data;
                }).catch(err => {
                    throw err
                })

                //await axios.get('Hotel/HouseList', {}).then(response => {
                //    this.houseType = response.data
                //});

                axios.get('Hotel/GetHotelRoomByIDAsync/' + this.editData.roomID).then(response => {
                    this.editedData = response.data
                    this.editedData.fareID = response.data.fareID.toString()
                })
                //console.log(this.editedData)
                //this.updated = false;
            },
            async getRoom(val) {
                const roomList = await axios.get('Hotel/RoomList/' + val);
                this.roomType = roomList.data
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        RoomID: this.editedData.roomID,
                        FareID: parseInt(this.editedData.fareID),
                        RoomCategoryId: parseInt(this.editedData.roomCategoryID),
                        RoomTypeId: parseInt(this.editedData.roomTypeID),
                        Status: true,
                        RoomNumber: this.editedData.roomNumber,
                        //RoomCategoryID: this.editedData.roomCategoryID,
                        //RoomTypeID: this.editedData.roomTypeID,
                    };
                    axios.post("Hotel/UpdateHotelRoomAsync", param).then(response => {
                        if (response.data.success) {
                            this.updated = true;
                            this.editedData = {};
                            this.returnData.status = true
                            this.returnData.message = "Room Updated Successfully"
                        }
                    });
                    this.submitStatus = "PENDING";
                    setTimeout(() => {
                        this.submitStatus = "OK";
                    }, 500);
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
                this.editedData.houseType = "";
                this.editedData.roomType = "";
                this.editedData.RoomNumber = "";
            }
        }
    };
</script>

<style scoped>
</style>